import { Controller } from 'stimulus';
import $ from 'jquery';
import Rails from '@rails/ujs';
import moment from 'moment';
import flatpickr from 'flatpickr';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import 'flatpickr/dist/flatpickr.min.css';

export default class extends Controller {
  static targets = ['search', 'dateRange'];

  connect() {
    const { remote, filters, searchFilters } = this.element.dataset;
    this.currentSearchFilters = JSON.parse(searchFilters);

    if (remote === 'true') {
      this.remote = true;
      this.currentFilters = JSON.parse(filters);
    }

    $(this.searchTarget).select2({
      placeholder: 'Type here to filter by customer, quote or marquee',
      allowClear: true,
      minimumInputLength: 3,
      width: '100%',
      multiple: true,
      data: this.currentSearchFilters,
      ajax: {
        url: '/api/customers/search',
        dataType: 'json',
      },
    });

    $(this.searchTarget).on('select2:select', () => this.updateSearch());
    $(this.searchTarget).on('select2:unselect', () => this.updateSearch());

    this.dateRange = flatpickr(this.dateRangeTarget, {
      mode: 'range',
      onChange: () => this.updatedDateRange(),
    });
  }

  disconnect() {
    $(this.searchTarget).off('select2:select');
    $(this.searchTarget).off('select2:unselect');
  }

  currentUrlSearchParams() {
    if (this.remote) {
      const urlParams = new URLSearchParams(this.currentFilters);

      // Include any current search filters from the select2 dropdown.
      this.currentSearchFilters.forEach((filter) => {
        urlParams.append(`${filter.category}[]`, filter.id);
      });

      return urlParams;
    }

    return new URLSearchParams(window.location.search);
  }

  updateSearch() {
    const selectedData = $(this.searchTarget).select2('data');

    const urlParams = this.currentUrlSearchParams();

    // Delete old search selection before applying the updated selection.
    urlParams.delete('customer[]');
    urlParams.delete('quote[]');
    urlParams.delete('marquee[]');

    selectedData.forEach((filter) => {
      urlParams.append(`${filter.category}[]`, filter.id);
    });

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  updatedDateRange() {
    const {
      selectedDates: [startDate, endDate],
    } = this.dateRange;
    if (!startDate || !endDate) {
      return;
    }

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('start_date', moment(startDate).format());
    urlParams.set('end_date', moment(endDate).format());

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  sortClicked(event) {
    event.preventDefault();

    const { sortBy, sortOrder } = event.currentTarget.dataset;

    const urlParams = this.currentUrlSearchParams();
    urlParams.set('sort_by', sortBy);
    urlParams.set('sort_order', sortOrder);

    this.applyURLParams(urlParams);
  }

  clearFilters() {
    this.applyURLParams(new URLSearchParams());
  }

  showArchivedToggled(event) {
    const { checked } = event.target;
    const urlParams = this.currentUrlSearchParams();
    urlParams.set('show_archived', checked);

    // Display the results starting from the first page.
    urlParams.delete('page');

    this.applyURLParams(urlParams);
  }

  applyURLParams(urlParams) {
    if (!this.remote) {
      window.location.search = urlParams;
      return;
    }

    Rails.ajax({
      type: 'GET',
      url: `/agent/reports/all_quotes?${urlParams.toString()}`,
      dataType: 'script',
    });
  }
}
