import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import moment from 'moment';
import getDistanceToAddress from '../../api/getDistanceToAddress';
import getBookingInfoForDateRange from '../../api/getBookingInfoForDateRange';
import 'flatpickr/dist/flatpickr.min.css';

const DISTANCE_UNITS = {
  IMPERIAL: 'Imperial',
  METRIC: 'Metric',
};

export default class extends Controller {
  static targets = ['followUpForm', 'line1Field', 'line2Field', 'cityField', 'postcodeField',
    'countyField', 'distanceMessage', 'distanceMessageContainer', 'hireDateRange', 'hireStart',
    'hireEnd', 'setupDateRange', 'setupStart', 'setupEnd', 'takedownDateRange', 'takedownStart',
    'takedownEnd', 'followUpDate', 'followUpNotes', 'quoteName', 'createTaskButton',
    'addContactForm', 'bookingsAlertMessage', 'bookingsAlertContainer', 'hireDatesConfirmed',
  ];

  connect() {
    // select quote name text for easy type over
    this.quoteNameTarget.select();
    const bookingsReportEnabled = this.data.get('bookings-report-enabled') === 'true';
    // setup the date range selectors
    ['hire', 'setup', 'takedown'].forEach((rangePicker) => {
      const defaultDates = this[`${rangePicker}StartTarget`].value && this[`${rangePicker}EndTarget`].value ? [moment(this[`${rangePicker}StartTarget`].value).format('MMMM DD, YYYY'), moment(this[`${rangePicker}EndTarget`].value).format('MMMM DD, YYYY')] : [];
      flatpickr(this[`${rangePicker}DateRangeTarget`], {
        mode: 'range',
        wrap: true,
        dateFormat: 'F d, Y',
        defaultDate: defaultDates,
        onChange: async (selectedDates) => {
          if (selectedDates.length === 0) {
            this[`${rangePicker}StartTarget`].value = null;
            this[`${rangePicker}EndTarget`].value = null;
            if (rangePicker === 'hire') {
              this.hireDatesConfirmedTarget.checked = false;
              this.hireDatesConfirmedTarget.disabled = true;
            }
            return;
          }
          const [startDate, ...otherDates] = selectedDates;
          const endDate = otherDates.slice(-1)[0];
          this[`${rangePicker}StartTarget`].value = moment(startDate).format('YYYY-MM-DD');
          this[`${rangePicker}EndTarget`].value = moment(endDate).format('YYYY-MM-DD');
          if (rangePicker === 'hire') {
            if (startDate && endDate) {
              this.hireDatesConfirmedTarget.disabled = false;
              if (bookingsReportEnabled) {
                await this.checkBookingsForHireDateRange();
              }
            } else {
              this.hireDatesConfirmedTarget.disabled = true;
            }
          }
        },
      });
    });
    // setup the datepicker for followup
    flatpickr(this.followUpDateTarget, {
      dateFormat: 'F d, Y',
    });
  }

  followUpToggled(event) {
    event.preventDefault();
    this.followUpFormTarget.style.display = 'block';
    this.createTaskButtonTarget.disabled = true;
  }

  addContactToggled(event) {
    event.preventDefault();
    this.addContactFormTarget.style.display = this.addContactFormTarget.style.display === 'block' ? 'none' : 'block';
  }

  taskUpdated() {
    this.createTaskButtonTarget.disabled = (this.followUpNotesTarget.value === '' || this.followUpDateTarget.value === '');
  }

  postcodeFieldBlur({ currentTarget }) {
    this.postcodeFieldTarget.value = currentTarget.value.toUpperCase();
  }

  async updateDistanceToAddress() {
    const deliveryAddressFields = {
      line1: this.line1FieldTarget.value,
      line2: this.line2FieldTarget.value,
      city: this.cityFieldTarget.value,
      postcode: this.postcodeFieldTarget.value,
      county: this.countyFieldTarget.value,
    };

    // Return unless there is at least one field filled in.
    if (Object.values(deliveryAddressFields).every((prop) => prop == null || prop === '')) {
      return;
    }

    const distanceResult = await getDistanceToAddress(deliveryAddressFields);
    let distanceMessage = '';
    if (distanceResult.data.known) {
      const unit = distanceResult.data.units === DISTANCE_UNITS.IMPERIAL ? 'mile' : 'km';
      distanceMessage += `Distance to event ${distanceResult.data.distance} ${unit}s</br>`;
      distanceMessage += `Estimated delivery cost @${distanceResult.data.delivery_charge_per_unit} per ${unit} is ${distanceResult.data.estimated_delivery_charge}`;
    } else {
      distanceMessage += `Unknown postcode ${distanceResult.data.postcode}, cannot estimate delivery charge`;
    }
    this.distanceMessageTarget.innerHTML = distanceMessage;
    this.distanceMessageContainerTarget.style.display = 'flex';
  }

  async checkBookingsForHireDateRange() {
    const startDate = moment(this.hireStartTarget.value);
    const endDate = moment(this.hireEndTarget.value);
    const { numBookings, numQuotes } = await getBookingInfoForDateRange({ startDate, endDate });
    if (numBookings === 0 && numQuotes === 0) return;

    let bookingMessage = 'Sales report, you have ';
    if (numBookings > 0) {
      bookingMessage = bookingMessage.concat(`${numBookings} bookings `);
      if (numQuotes > 0) {
        bookingMessage = bookingMessage.concat('and ');
      }
    }
    if (numQuotes > 0) {
      bookingMessage = bookingMessage.concat(`${numQuotes} quotes `);
    }
    bookingMessage = bookingMessage.concat('within the current hire period');
    const bookingReportPath = `/agent/reports/bookings?start_date=${startDate}&end_date=${endDate}`;
    this.bookingsAlertMessageTarget.innerHTML = bookingMessage.concat(`<br/><a href='${bookingReportPath}' target='_blank'>Click to view bookings report</a>`);
    this.bookingsAlertContainerTarget.style.display = 'flex';
  }
}
