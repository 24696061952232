import { Controller } from 'stimulus';
import sortable from 'html5sortable/dist/html5sortable.es';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['stepsContainer'];

  // eslint-disable-next-line class-methods-use-this
  connect() {
    sortable('tbody.tbody-sortable', {
      items: '.tr-sortable',
      acceptFrom: 'tbody.tbody-sortable',
      placeholder: '<tr class="table-primary"><td colspan="2"><span class="center">The row will appear here</span></td></tr>',
      forcePlaceholderSize: false,
    }).forEach((list) => {
      list.addEventListener('sortupdate', () => {
        this.forceUpdateStepPositions();
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  stepVisibilityChanged(event) {
    const visible = event.target.checked;
    const $row = $(event.target).parents('tr:first');

    if (visible) {
      let $targetBefore = $row;

      while ($targetBefore.prev().length > 0 && !$targetBefore.prev().find('input[type=checkbox][name*="enabled"]').prop('checked')) {
        $targetBefore = $targetBefore.prev();
      }

      $row.insertBefore($targetBefore);
    } else {
      let $targetAfter = $row;

      while ($targetAfter.next().length > 0 && $targetAfter.next().find('input[type=checkbox][name*="enabled"]').prop('checked')) {
        $targetAfter = $targetAfter.next();
      }

      $row.insertAfter($targetAfter);
    }

    this.forceUpdateStepPositions();
  }

  forceUpdateStepPositions() {
    this.stepsContainerTarget.children.forEach((row, index) => {
      // Force the position of all items to be in accordance with their position in the DOM.
      $(row).find('input[type=hidden][name*="position"]').val(index + 1);
    });
  }
}
